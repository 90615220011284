var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.editorOrdersTableHeaders,"items":_vm.orders,"search":_vm.$store.state.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_vm._v(_vm._s(item.id || ""))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_c('span',{domProps:{"innerHTML":_vm._s(item.title)}})])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [(item.client_id)?_c('router-link',{attrs:{"to":{ name: 'ClientProfile', params: { id: item.client_id } }}},[_vm._v(_vm._s(item.client_name || ""))]):_vm._e()]}},{key:"item.status_desc",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"badge badge-secondary"},[_vm._v(" "+_vm._s(item.status_desc || "")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',[_c('router-link',{staticClass:"\n          white--text\n          v-btn v-btn--contained v-btn--fab v-btn--round\n          theme--light\n          v-size--x-small\n          success\n        ",attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_c('v-icon',{staticClass:"white--text",attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }